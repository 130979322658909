<template>
  <div>
    <div class="content w-full px-5 py-10 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#003E87"
      ></loading>
      <div className="" v-if="userIsRegister">
        <div class="text-blue-700 font-bold text-2xl mb-3 text-center strokeme">
          ส่งใบเสร็จร่วมกิจกรรม
        </div>
        <img
          src="../assets/images/lotus-logo-primary.svg"
          class="mx-auto m-10"
        />
        <div class="mb-3">
          <label class="mb-3"
            ><span class="font-bold">เลขที่ใบเสร็จ </span>
            <span class="text-red-500 text-xs"></span
          ></label>
          <input
            v-model="submitBillNo"
            type="text"
            placeholder="เลขที่ใบเสร็จ"
            class="px-3 py-3 mt-2 relative border-2 border-blue-500 rounded-lg w-full"
          />
        </div>

        <div class="mb-3">
          <label class="mb-3"
            ><span class="font-bold">จำนวนเงิน </span>
            <span class="text-red-500 text-xs">(บาท)</span></label
          >
          <input
            v-model="submitBuyAmount"
            type="tel"
            placeholder="จำนวนเงิน"
            class="px-3 py-3 mt-2 relative border-2 border-blue-500 rounded-lg w-full"
          />
        </div>
        <div class="text-black font-bold mb-2">อัปโหลดใบเสร็จ</div>
        <div
          class="mt-1 flex justify-center border-2 bg-white border-blue-500 rounded-lg"
        >
          <div class="space-1 p-2 text-center">
            <div class="flex text-sm text-yellow-600">
              <label
                for="file-upload"
                class="relative cursor-pointer rounded-md font-medium"
              >
                <div class="w-full py-5" v-if="submitPicUrl == null">
                  <svg
                    class="mx-auto h-12 w-12 text-blue-500"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="text-black" v-if="submitPicUrl == null">
                    รูปภาพใบเสร็จ
                  </div>
                </div>
                <div v-if="submitPicUrl" class="text-center">
                  <img :src="submitPicUrl" style="width: 100%" />
                  <!-- <div
                  v-if="submitPicUrl"
                  class="mt-3 uppercase overflow-ellipsis"
                >
                  {{ submitPicName }}
                </div> -->
                  <div class="mt-3 text-black">กดที่รูปเพื่อเปลี่ยนภาพ</div>
                </div>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  class="sr-only"
                  @change="onFileChange"
                />
              </label>
            </div>
            <p class="text-xs text-gray-500"></p>
          </div>
        </div>
        <div class="mt-8 grid gap-4 grid-cols-2">
          <button
            @click="goSubmit"
            class="w-full text-lg rounded-lg bg-gradient-to-b from-blue-700 to-blue-800 text-white py-2"
          >
            ส่งใบเสร็จ
          </button>
          <button
            @click="liffCloseWindow"
            class="w-full text-lg rounded-lg bg-gray-400 text-white py-2"
          >
            ยกเลิก
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
ul,
li {
  list-style: none;
  margin-left: 0 !important;
}
.max-height-48 {
  max-height: 200px;
}
</style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  ClipboardCopyIcon,
  ShoppingCartIcon,
  // CalendarIcon,
} from "@heroicons/vue/outline";
import liff from "@line/liff";
import router from "../router";
import Service from "../service/service";
import configs from "../config";
export default {
  name: "Submit",
  data() {
    return {
      userLineId: null,
      userIsRegister: false,
      isLoading: true,
      fullPage: true,
      submitBuyAmount: "",
      submitBillNo: null,
      submitPic: null,
      submitPicUrl: null,
      submitPicName: null,
      configs,
      userCoordinates: null,
      minBill: 0,
      isNotStart: false,
      isEnded: false,
    };
  },
  components: {
    Loading,
    ClipboardCopyIcon,
    // CalendarIcon,
    ShoppingCartIcon,
  },
  service: null,
  created() {
    let today = new Date();
    let tomonth = today.getMonth() + 1;
    let month = tomonth < 10 ? "0" + tomonth : tomonth;
    let todate = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    this.service = new Service();
    this.submitBuyDate = today.getFullYear() + "-" + month + "-" + todate;

    this.$getLocation()
      .then((coordinates) => {
        this.userCoordinates = coordinates;
        // console.log(coordinates);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;

      this.service.memberIsRegister(this.userLineId).then((data) => {
        // console.log(data);
        if (data.isRegisted === 1) {
          this.userIsRegister = true;
          console.log(data.custInfo);
          // this.goStep1();
        } else {
          this.$swal
            .fire({
              html: "คุณยังไม่ได้ลงทะเบียน<br />กรุณาลงทะเบียนก่อนร่วมกิจกรรม",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#b91c1c",

              confirmButtonText: "ไปลงทะเบียน  &rarr;",
            })
            .then((result) => {
              if (result.isConfirmed) {
                router.push("/register");
              }
            });
        }
        this.isLoading = false;
      });
    }
  },
  methods: {
    goStep1() {
      let isError = false;
      let txtError = "";

      //   if (this.submitProduct === null || this.submitProduct === "") {
      //     isError = true;
      //     txtError += "<li>กรุณาเลือกสินค้าที่ร่วมรายการ</li>";
      //   }

      if (!isError) {
        this.step1 = false;
        this.step2 = true;
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonColor: "#b91c1c",
          cancelButtonText: "ตกลง",
        });
      }
    },

    onFileChange(e) {
      this.submitPic = e.target.files[0];
      this.submitPicName = e.target.files[0].name;
      this.submitPicUrl = URL.createObjectURL(this.submitPic);
    },

    async goSubmit() {
      let isError = false;
      let txtError = "";
      this.btnDisable = true;

      if (this.submitBillNo === null || this.submitBillNo === "") {
        isError = true;
        txtError += "<li>กรุณากรอกเลขที่ใบเสร็จ</li>";
      }

      if (
        this.submitBuyAmount === null ||
        this.submitBuyAmount === "" ||
        this.submitBuyAmount == 0
      ) {
        isError = true;
        txtError += "<li>กรุณาระบุจำนวนเงินที่ซื้อ</li>";
      } else {
      }

      if (this.submitPic === null || this.submitPic === "") {
        isError = true;
        txtError += "<li>กรุณาถ่ายรูปหรืออัพโหลดใบเสร็จ</li>";
      }

      if (!isError) {
        // upload image first
        this.isLoading = true;
        let imgFormData = new FormData();
        imgFormData.append("image", this.submitPic);
        this.service
          .awsUpload(imgFormData)
          .then((data) => {
            if (data.isSuccess === 1) {
              let formData = new FormData();
              formData.append("line_id", this.userLineId);
              formData.append("bill_pic", data.uploadResult);
              formData.append("bill_no", this.submitBillNo);
              formData.append("bill_amount", this.submitBuyAmount);

              formData.append("bill_shop", "");
              if (this.userCoordinates) {
                formData.append(
                  "bill_geolocation",
                  this.userCoordinates.lat + "," + this.userCoordinates.lng
                );
              } else {
                formData.append("bill_geolocation", "0,0");
              }

              this.service
                .memberSubmitBill(formData)
                .then((data) => {
                  if (data.isSuccess === 1) {
                    router.push("/success");

                    this.isLoading = false;
                  } else {
                    this.isLoading = false;
                    this.btnDisable = false;
                    this.$swal({
                      html: data.msgError,
                      title: "พบข้อผิดพลาด",
                      icon: "error",
                      showConfirmButton: false,
                      showCancelButton: true,
                      cancelButtonColor: "#b91c1c",
                      cancelButtonText: "ตกลง",
                    });
                  }
                })
                .catch((error) => console.error("member-submit-bill", error));
            } else {
              this.$swal({
                html: data.uploadResult,
                title: "พบข้อผิดพลาด",
                icon: "error",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: "#b91c1c",
                cancelButtonText: "ตกลง",
              });
            }
          })
          .catch((error) => console.error("s3-upload", error));

        // submit to backend
      } else {
        this.isLoading = false;
        this.btnDisable = false;
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonColor: "#b91c1c",
          cancelButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
