<template>
  <div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto px-5 py-10">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#003E87"
      ></loading>
      <div class="text-blue-700 font-bold text-2xl mb-3 text-center strokeme">
        ตรวจสอบรางวัลคงเหลือ
      </div>
      <div
        class="p-1 bg-gradient-to-b from-yellow-700 via-yellow-500 to-yellow-700 rounded-2xl"
      >
        <div class="p-5 bg-white rounded-xl text-center">
          <span class="mt-5 text-xl font-bold text-blue-700"
            >Electric Vintage Enfa Car<br />รถไฟฟ้าสไดล์วินเทจเอนฟา <br /></span
          ><span class="font-bold text-lg text-gray-700"
            >มูลค่า 3,500 บาท
          </span>
          <img
            src="../assets/images/reward-2309el.png"
            class="mx-auto my-8"
            style="width: 70%"
            alt=""
          />
          <div class="text-xl leading-6 text-center">
            จำนวนรางวัลคงเหลือ
            <span class="text-blue-800 font-bold">{{ rewards.RW_REMAIN }}</span>
            รางวัล
          </div>

          <div class="mt-8 grid gap-4 grid-cols-2">
            <button
              @click="goTo('/redeem')"
              class="w-full text-lg rounded-lg bg-gradient-to-b from-blue-700 to-blue-800 text-white py-2"
            >
              แลกของรางวัล
            </button>
            <button
              @click="liffCloseWindow"
              class="w-full text-lg rounded-lg bg-gray-400 text-white py-2"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import liff from "@line/liff";
import Service from "../service/service";
import configs from "../config";
export default {
  data() {
    return {
      userLineId: null,
      userLineDisplayName: null,
      userLinePictureUrl: null,
      userIsRegister: false,
      custInfo: null,
      isLoading: false,
      fullPage: true,
      configs,
      rewards: "",
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },

  async mounted() {
    this.isLoading = true;
    await this.service.getReward("RCAR").then((data) => {
      this.rewards = data.rewardData[0];
    });
    this.isLoading = false;
  },
  methods: {
    goTo(r) {
      this.$router.push(r);
    },
    liffCloseWindow() {
      liff.closeWindow();
    },
  },
};
</script>
